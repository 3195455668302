<template>
  <div>
    <h4 class="mb-3">Please select a suitable time</h4>
    <hr>
    <div class="row">
      <div class="col-6 col-md-3 mb-2 mt-1" v-for="(time, key) in times" :key="key">
        <div class="card bg-light border-0 shadow-sm">
          <div class="card-body text-center">
            <h3 class="mb-3 text-primary">{{ translateTime(key, client) }}</h3>
            <button class="btn btn-outline-primary btn-sm" @click="selectTime(key)">Select time</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["client", "times"],
  methods: {
    selectTime(time) {
      this.$emit("selected", time);
    },
  },
  filters: {
  }
};
</script>

<style>
</style>