<template>
  <div>
    <h4 class="mb-3">Choose the date you would like your {{ service.name }} to be on</h4>
    <hr />
    <busy v-if="busy"></busy>
    <div class="row">
      <div class="col-6 col-md-3 mb-3" v-for="(times, key) in dates" :key="key">
        <div class="card bg-light border-0 shadow-sm">
          <div class="card-body text-center">
            <h3 class="mb-3 text-primary">{{ key | formatDate }}</h3>
            <button
              class="btn btn-outline-primary btn-sm"
              @click="selectDate(key, times)"
            >Select date</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Busy from "./Busy";

export default {
  props: ["client", "practitioner", "service", "deliveryMethod"],
  data() {
    return {
      dates: [],
      busy: false
    };
  },
  methods: {
    selectDate(date, times) {
      this.$emit("selected", date, times);
    },
    fetchDates() {
      this.busy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/client/appointments/fetch-dates", {
          practitioner_service_id: this.service.id,
          delivery_method: this.deliveryMethod
        })
        .then(({ data }) => {
          this.dates = data;
          this.busy = false;
        });
    }
  },
  mounted() {
    this.fetchDates();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatTime(time) {
      return time.substr(0, time.lastIndexOf(":"));
    }
  },
  components: {
    Busy
  }
};
</script>

<style>
</style>
