<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card bg-light border-0 shadow-sm">
          <div class="card-body">
            <h4 class="mb-4" v-if="!confirmed">Please confirm your appointment details</h4>
            <h4 class="mb-4" v-if="confirmed">Your appointment has been booked</h4>

            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="row">
                  <div class="col-4">Date</div>
                  <div class="col">{{date | formatDate}}</div>
                </div>
              </li>

              <li class="list-group-item">
                <div class="row">
                  <div class="col-4">Time</div>
                  <div class="col">{{translateTime(time, client)}}</div>
                </div>
              </li>

              <li class="list-group-item">
                <div class="row">
                  <div class="col-4">Package</div>
                  <div class="col">{{package.name}}</div>
                </div>
              </li>

              <li class="list-group-item" v-if="package.price > 0">
                <div class="row">
                  <div class="col-4">Price</div>
                  <div class="col">{{package.price_display}}</div>
                </div>
              </li>

              <li class="list-group-item">
                <div class="row">
                  <div class="col-4">Method</div>
                  <div class="col text-capitalize">{{ method | formatMethod }}</div>
                </div>
              </li>

              <li class="list-group-item">
                <div class="row" v-if="!confirmed">
                  <div class="col-auto ml-auto">
                    <button class="btn btn-primary" :disabled="busy" @click="bookAppointment">
                      <i class="fad mr-2" :class="busy ? 'fa-spinner fa-spin' : 'fa-check'"></i>Confirm Appointment
                    </button>
                  </div>
                </div>

                <div class="row" v-if="confirmed">
                  <div class="col-auto ml-auto">
                    <button class="btn btn-primary" @click="$router.push('/appointments')">View Upcoming Appointments</button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["package", "method", "date", "time", "client"],
  data() {
    return {
      confirmed: false,
      busy: false
    };
  },
  methods: {
    startAgain() {
      this.$emit("startAgain");
    },
    bookAppointment() {
      this.busy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/client/appointments", {
          client: this.client.id,
          practitioner: this.firstService.practitioner_id,
          package: this.package.id,
          method: this.method,
          datetime: this.datetime,
        })
        .then(({ data }) => {
          this.confirmed = true;
          this.busy = false;
        });
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatMethod(str) {
      if (str === 'standard') {
        return 'In Person'
      } else {
        return str;
      }
    }
  },
  computed: {
    datetime() {
      return new Date(this.date.replace(/-/g, "/") + " " + this.time.replace(/-/g, "/"));
    },
    firstService() {
      return this.package.services.length > 0 ? this.package.services[0] : null;
    },
  }
};
</script>

<style>
</style>
