<template>
  <div>
    <h4 class="mb-3">Please select your practitioner</h4>
    <hr>
    <div class="row" v-if="practitioners.length == 0">
      <div class="col">
        <p class="alert alert-info">Please contact {{$store.user.clinic.name}} directly to request an appointment.</p>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 col-md-3 mb-3"
        v-for="practitioner in practitioners"
        :key="practitioner.id"
      >
        <div class="card bg-light border-0 shadow-sm">
          <div class="card-body text-center">
            <img
              :src="practitioner.profile_pic_url"
              :alt="practitioner.name"
              class="rounded-circle mx-auto"
              width="75"
              height="75"
            />
            <h5 class="mb-3 text-primary">{{ practitioner.name }}</h5>
            <button
              class="btn btn-outline-primary btn-sm"
              @click="selectPractitioner(practitioner)"
            >Select practitioner</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["practitioners"],
  methods: {
    selectPractitioner(practitioner) {
      this.$emit("selected", practitioner);
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatTime(time) {
      return time.substr(0, time.lastIndexOf(":"));
    }
  },
};
</script>
