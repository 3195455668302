<template>
  <div class="container">
    <div class="row">
      <div v-if="busy" class="col-12">
        <busy />
      </div>
      <div
        v-else-if="!busy && followupServices.length && followupPackages.length"
        class="col-12 mx-auto mb-3"
      >
        <div class="accordion" id="sectionsAccordion">
          <div class="shadow">
            <div class="card shadow-none w-100">
              <div
                class="card-header pl-1 pr-3"
                :id="'heading1'"
              >
                <h4
                  class="mb-0"
                  data-toggle="collapse"
                  :data-target="'#collapse1'"
                  aria-expanded="true"
                  :aria-controls="'collapse1'"
                >
                  <i class="fad fa-caret-down mr-2 ml-3" />
                  Booked package(s) with outstanding services
                </h4>
              </div>
            </div>
            <div class="card">
              <div
                :id="'collapse1'"
                class="collapse show"
                :aria-labelledby="'heading1'"
                data-parent="#sectionsAccordion"
              >
                <div class="card-body">
                  <packages-followup
                    :client="client"
                    :packages="followupPackages"
                    :services="followupServices"
                    :practitioner="practitioner"
                    v-if="stepFollowup == 1"
                    @selected="selectedFollowupPackageServicePractitioner"
                  />

                  <service-method
                    :client="client"
                    :service="service"
                    :practitioner="practitioner"
                    v-if="stepFollowup == 3 && service"
                    @selected="selectedFollowupMethod"
                  />

                  <service-date
                    :client="client"
                    v-if="stepFollowup == 4 && service"
                    :practitioner="practitioner"
                    :service="service"
                    @selected="selectedFollowupDate"
                  />

                  <select-time
                    :client="client"
                    v-if="stepFollowup == 5"
                    :times="times"
                    @selected="selectedFollowupTime"
                  />

                  <service-confirm
                    :client="client"
                    v-if="stepFollowup == 6 && service"
                    :practitioner="practitioner"
                    :service="service"
                    :package="package"
                    :consultationId="followupSelectedConsultationId"
                    :followup="true"
                    :method="method"
                    :date="date"
                    :time="time"
                    @startAgain="startAgainFollowup"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!busy"
        class="col-12 mx-auto"
      >
        <div class="card border-0 shadow">
          <div class="card-body">
            <practitioner :client="client"
                          v-if="step == 1"
                          :practitioners="practitioners"
                          @selected="selectedPractitioner" />

            <packages :client="client"
                      v-if="step == 1"
                      @selectedPackage="selectedPackage" />

            <service
              :client="client"
              :practitioner="practitioner"
              v-if="step == 2"
              @selectedService="selectedService" />

            <service-method
              :client="client"
              :service="service"
              :practitioner="practitioner"
              v-if="step == 3 && service"
              @selected="selectedMethod" />

            <package-method
                :client="client"
                :package="package"
                v-if="step == 3 && package"
                @selected="selectedMethod" />

            <service-date
              :client="client"
              v-if="step == 4 && service"
              :practitioner="practitioner"
              :service="service"
              :delivery-method="method"
              @selected="selectedDate" />

            <package-date
              :client="client"
              v-if="step == 4 && package"
              :practitioner="practitioner"
              :package="package"
              @selected="selectedDate" />

            <select-time :client="client"
                         v-if="step == 5"
                         :times="times"
                         @selected="selectedTime" />

            <service-confirm
              :client="client"
              v-if="step == 6 && service"
              :practitioner="practitioner"
              :service="service"
              :method="method"
              :date="date"
              :time="time"
              @startAgain="startAgain" />

            <package-confirm
                :client="client"
                v-if="step == 6 && package"
                :package="package"
                :method="method"
                :date="date"
                :time="time"
                @startAgain="startAgain" />
          </div>

          <div class="card-footer">
            <button
              v-if="step > 1"
              @click="prevPage"
              class="btn btn-light btn-sm"
            >
              <i class="fad fa-arrow-left"></i>
              Previous
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Busy from "./create-partials/Busy";
import Practitioner from "./create-partials/Practitioner";
import Service from "./create-partials/Service";
import ServiceMethod from "./create-partials/ServiceMethod";
import ServiceDate from "./create-partials/ServiceDate";
import SelectTime from "./create-partials/Time";
import ServiceConfirm from "./create-partials/ServiceConfirm";
import PackageMethod from "./create-partials/PackageMethod";
import PackageConfirm from "./create-partials/PackageConfirm";
import Packages from "@/views/areas/appointments/create-partials/Packages";
import PackagesFollowup from "@/views/areas/appointments/create-partials/PackagesFollowup";
import PackageDate from "@/views/areas/appointments/create-partials/PackageDate";

export default {
  props: [],
  data() {
    return {
      step: 1,
      stepFollowup: 1,
      practitioner: null,
      practitioners: null,
      service: null,
      followupServices: [],
      followupSelectedConsultationId: null,
      package: null,
      followupPackages: [],
      method: null,
      date: null,
      busy: true,
      times: [],
      time: null
    };
  },
  computed: {
    client() {
      return this.$store.user
    }
  },
  methods: {
    fetchPackagesWithOutstandingServices() {
      this.busy = true;
      return this.$axios
          .get(process.env.VUE_APP_API_URL + "/client/appointments/fetch-consultations-unbooked")
          .then(({ data }) => {
            let packages = {}
            let services = {}
            data.map(d => {
              packages[d.package.id] = d.package;
              services[d.service.id] = d.service;
              services[d.service.id]['package'] = d.package;
              services[d.service.id]['consultationId'] = d.id;
            });
            this.followupServices = Object.values(services);
            this.followupPackages = Object.values(packages);
            this.busy = false;
          });
    },
    selectedPractitioner(practitioner) {
      this.practitioner = practitioner;
      this.step = 2;
    },
    selectedService(service) {
      this.service = service;
      this.step = 3;
    },
    selectedPackage(p) {
      this.package = p;
      this.step = 3;
    },
    selectedFollowupPackageServicePractitioner(data) {
      this.service = data.service;
      this.package = data.package;
      this.followupSelectedConsultationId = data.consultationId;
      this.practitioner = Object.values(this.practitioners).find(pract => {
        return pract.id === data.practitionerId
      })
      this.stepFollowup = 3;
    },

    selectedFollowupMethod(method) {
      this.method = method;
      this.stepFollowup = 4;
    },
    selectedFollowupDate(date, times) {
      this.times = times;
      this.date = date;
      this.stepFollowup = 5;
    },
    selectedFollowupTime(time) {
      this.time = time;
      this.stepFollowup = 6;
    },
    startAgainFollowup() {
      this.stepFollowup = 1;
      this.package = null;
      this.practitioner = null;
      this.service = null;
      this.date = null;
      this.times = [];
      this.time = null;
    },
    selectedMethod(method) {
      this.method = method;
      this.step = 4;
    },
    selectedDate(date, times) {
      this.times = times;
      this.date = date;
      this.step = 5;
    },
    selectedTime(time) {
      this.time = time;
      this.step = 6;
    },
    startAgain() {
      this.step = 1;
      this.package = null;
      this.practitioner = null;
      this.service = null;
      this.date = null;
      this.times = [];
      this.time = null;
    },
    prevPage() {
      this.step = this.step-1;
    },
    fetchPractitioners() {
      this.busy = true;
      return this.$axios
        .get(process.env.VUE_APP_API_URL + "/client/appointments/fetch-practitioners/" + this.$store.user.id)
        .then(({ data }) => {
          this.practitioners = data;
          this.busy = false;
        });
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatTime(time) {
      return time.substr(0, time.lastIndexOf(":"));
    }
  },
  async mounted() {
    await this.fetchPractitioners();
    await this.fetchPackagesWithOutstandingServices();
  },
  components: {
    Busy,
    PackageDate,
    Packages,
    PackagesFollowup,
    PackageConfirm,
    PackageMethod,
    Practitioner,
    Service,
    ServiceMethod,
    ServiceDate,
    SelectTime,
    ServiceConfirm
  }
};
</script>

<style>
.accordion h4 {
  cursor: pointer;
}
</style>
