<template>
  <div>
    <h4 class="mb-3">Choose the service you would like with {{ practitioner.name }}</h4>
    <hr />
    <busy v-if="busy"></busy>
    <div v-else>
      <div class="row" v-if="services.length > 0">
        <div class="col-md-4 mb-3" v-for="s in services">
          <div class="card cursor-pointer h-100" @click="selectService(s)">
            <img v-if="s.cover_image"
                 :src="s.cover_image"
                 class="card-img-top mx-auto p-5"
                 style="height: auto; width: 100%">
            <img v-else
                 :src="practitioner.clinic.logo"
                 class="card-img-top mx-auto p-5"
                 style="height: auto; width: 100%">
            <div class="card-body text-center">
              <h5>{{s.name}}</h5>
              <h6>{{s.duration}} mins | {{s.price_display}}</h6>
              <button class="btn btn-sm btn-primary">
                <i class="fad fa-check mr-2"></i>Select service
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-8 mx-auto text-center">
          <h3 class="my-3"><i class="fad fa-info-circle fa-3x"></i></h3>
          <h5>{{ practitioner.name }} currently has no services available.</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Busy from "./Busy";

export default {
  props: ["client", "practitioner"],
  data() {
    return {
      services: [],
      busy: false
    };
  },
  methods: {
    selectService(service) {
      this.$emit("selectedService", service);
    },
    fetchServices() {
      this.busy = true;
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/client/appointments/fetch-services/" + this.practitioner.id)
        .then(({ data }) => {
          this.services = data;
          this.busy = false;
        });
    },
  },
  mounted() {
    this.fetchServices();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatTime(time) {
      return time.substr(0, time.lastIndexOf(":"));
    }
  },
  components: {
    Busy
  }
};
</script>

<style>
</style>
