<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card bg-light border-0 shadow-sm">
          <div class="card-body">
            <h4 class="mb-4" v-if="!confirmed && !followup">
              Please confirm your appointment details
            </h4>
            <h4 class="mb-4" v-if="confirmed && !followup">Your appointment has been requested</h4>

            <h4 class="mb-4" v-if="!confirmed && followup">Booking confirmation</h4>
            <h4 class="mb-4" v-if="confirmed && followup">Booking confirmed!</h4>

            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="row">
                  <div class="col-4">Date</div>
                  <div class="col">{{date | formatDate}}</div>
                </div>
              </li>

              <li class="list-group-item">
                <div class="row">
                  <div class="col-4">Time</div>
                  <div class="col">{{translateTime(time, client)}}</div>
                </div>
              </li>

              <li class="list-group-item">
                <div class="row">
                  <div class="col-4">Service</div>
                  <div class="col">{{service.name}}</div>
                </div>
              </li>

              <li class="list-group-item" v-if="package">
                <div class="row">
                  <div class="col-4">Package</div>
                  <div class="col">{{package.name}}</div>
                </div>
              </li>

              <li class="list-group-item" v-if="!followup && service.price > 0">
                <div class="row">
                  <div class="col-4">Price</div>
                  <div class="col">{{service.price_display}}</div>
                </div>
              </li>

              <li class="list-group-item">
                <div class="row">
                  <div class="col-4">Method</div>
                  <div class="col text-capitalize">{{ method | formatMethod}}</div>
                </div>
              </li>

              <li class="list-group-item">
                <div class="row">
                  <div class="col-4">Practitioner</div>
                  <div class="col text-capitalize">{{ practitioner.name }}</div>
                </div>
              </li>

              <li class="list-group-item">
                <div class="row" v-if="!confirmed">
                  <div class="col-auto ml-auto">
                    <button class="btn btn-primary" :disabled="busy" @click="bookAppointment">
                      <i class="fad mr-2" :class="busy ? 'fa-spinner fa-spin' : 'fa-check'"></i>Confirm Appointment
                    </button>
                  </div>
                </div>

                <div class="row" v-if="confirmed">
                  <div class="col">
                    <router-link to="/appointments" class="btn btn-primary colorWhite">View Upcoming Appointments</router-link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "package",
    "practitioner",
    "service",
    "consultationId",
    "method",
    "date",
    "time",
    "client",
    "followup"
  ],
  data() {
    return {
      confirmed: false,
      busy: false
    };
  },
  methods: {
    startAgain() {
      if (this.followup === true) {
        this.$emit("startAgainFollowup");
        return;
      }
      this.$emit("startAgain");
    },
    bookAppointment() {
      this.busy = true;
      if (this.followup === true) {
        this.bookAppointmentFollowup()
        return;
      }
      this.bookAppointmentNormal()
    },
    bookAppointmentNormal() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/client/appointments", {
          client: this.client.id,
          practitioner: this.practitioner.id,
          service: this.service.id,
          method: this.method,
          datetime: this.datetime,
        })
        .then(({ data }) => {
          this.confirmed = true;
          this.busy = false;
        });
    },
    bookAppointmentFollowup() {
      if (!this.consultationId) {
        throw new Error('missing consultation id')
      }
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/client/appointments/followup", {
          client: this.client.id,
          consultationId: this.consultationId,
          practitioner: this.practitioner.id,
          package: this.package.id,
          service: this.service.id,
          method: this.method,
          datetime: this.datetime,
        })
        .then(({ data }) => {
          this.confirmed = true;
          this.busy = false;
        });
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatMethod(str) {
      if (str === 'standard') {
        return 'In Person'
      } else {
        return str;
      }
    }
  },
  computed: {
    datetime() {
      return new Date(this.date.replace(/-/g, "/") + " " + this.time.replace(/-/g, "/"));
    }
  }
};
</script>

<style>
.colorWhite {
  color: white !important;
}
</style>
