<template>
  <div>
    <busy v-if="busy"></busy>
    <div v-else-if="packages.length > 0">
      <h4 class="mb-3">Clinic Packages</h4>
      <hr />
      <div class="row">
        <div class="col-12 col-md-3 mb-3" v-for="p in packages">
          <div class="card cursor-pointer h-100" @click="selectPackage(p)">
            <h5 class="badge badge-info" style="position: absolute; top: 5px; right: 5px;">Package</h5>
            <img v-if="p.cover_image"
                 :src="p.cover_image"
                 class="card-img-top mx-auto p-5"
                 style="height: auto; width: 100%">
            <img v-else
                 :src="client.clinic.logo"
                 class="card-img-top mx-auto p-5"
                 style="height: auto; width: 100%">
            <div class="card-body text-center">
              <h5>{{p.name}}</h5>
              <h6>Package | {{p.price_display}}</h6>
              <button class="btn btn-sm btn-primary">
                <i class="fad fa-check mr-2"></i>Select package
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Busy from "./Busy";

export default {
  props: ["client"],
  data() {
    return {
      packages: [],
      busy: false
    };
  },
  methods: {
    selectPackage(p) {
      this.$emit("selectedPackage", p);
    },
    fetchPackages() {
      this.$axios
          .get(process.env.VUE_APP_API_URL + "/client/appointments/fetch-packages")
          .then(({ data }) => {
            this.packages = data;
            this.busy = false;
          });
    }
  },
  mounted() {
    this.fetchPackages();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatTime(time) {
      return time.substr(0, time.lastIndexOf(":"));
    }
  },
  components: {
    Busy
  }
};
</script>

<style>
</style>
