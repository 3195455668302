<template>
  <div>
    <h4 class="mb-3">Select a method for your {{ package.name }}</h4>
    <hr />
    <busy v-if="busy"></busy>

    <div class="row" v-if="firstService">
      <div class="col-md-8 mx-auto">
        <ul class="list-group list-group-flush">
          <li v-if="firstService.practitioner_service.service.allow_standard"
              class="list-group-item cursor-pointer py-5"
              @click="selectMethod('standard')">
            <div class="row">
              <div class="col my-auto">Standard / In-Person Consultation</div>
              <div class="col-auto my-auto ml-auto">
                <button class="btn btn-sm btn-primary">
                  <i class="fad fa-check mr-2"></i>Select
                </button>
              </div>
            </div>
          </li>

          <li v-if="firstService.practitioner_service.service.allow_video"
              class="list-group-item cursor-pointer py-5"
              @click="selectMethod('video')">
            <div class="row">
              <div class="col my-auto">Video Consultation</div>
              <div class="col-auto my-auto ml-auto">
                <button class="btn btn-sm btn-primary">
                  <i class="fad fa-check mr-2"></i>Select
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Busy from "./Busy";

export default {
  props: ["client", "package"],
  data() {
    return {
      busy: false
    };
  },
  methods: {
    selectMethod(method) {
      this.$emit("selected", method);
    }
  },
  computed: {
    firstService() {
      return this.package.services.length > 0 ? this.package.services[0] : null;
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatTime(time) {
      return time.substr(0, time.lastIndexOf(":"));
    }
  },
  components: {
    Busy
  }
};
</script>

<style>
</style>
