<template>
  <div class="unbookedPackages">
    <busy v-if="busy"></busy>
    <div v-else-if="packages.length > 0">
      <div class="row">
        <div
          v-for="(p, index) in packages"
          class="col-sm-12 col-md-12 col-lg-12"
        >
          <div class="row">
            <div class="col-sm-12 col-md-3 col-lg-3 packageTile">
              <h5 class="badge badge-info" style="position: absolute; top: 5px; right: 5px;">Package</h5>
              <!-- Will enable once we know for the package payment state for certain -->
              <!-- <h5 class="badge badge-warning" style="position: absolute; top: 5px; left: 15px;">Paid</h5> -->
              <img v-if="p.cover_image"
                    :src="p.cover_image"
                    class="card-img-top mx-auto p-5"
                    style="height: auto; width: 100%">
              <img v-else
                    :src="client.clinic.logo"
                    class="card-img-top mx-auto p-5"
                    style="height: auto; width: 100%">
              <div class="card-body text-center">
                <h5>{{p.name}}</h5>
                <h6>{{p.price_display}}</h6>
              </div>
            </div>
            <div class="col-sm-12 col-md-9 col-lg-9 servicesTile">
              <div class="row">
                <div
                  class="col-sm-6 col-md-4 col-lg-3 mb-3"
                  v-for="s in getServices(p.id)"
                >
                  <div class="card serviceCard">
                    <div class="image">
                      <img
                        v-if="s.cover_image"
                        :src="s.cover_image"
                      />
                    </div>
                    <div class="card-body text-center">
                      <h5 class="serviceTitle">{{s.name | formatServiceTitle}}</h5>
                      <h6 class="serviceDuration">{{s.duration}} mins</h6>
                      <button
                        class="btn btn-sm btn-primary"
                        @click="selectFollowUpService(s)"
                      >
                        <i class="fad fa-check mr-2"/>Book
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr v-if="index < packages.length-1" class="my-4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Busy from "./Busy";

export default {
  props: ["client", "practitioner", "packages", "services"],
  data() {
    return {
      busy: false
    };
  },
  methods: {
    selectFollowUpService(service) {
      this.$emit("selected", {
        service,
        package: service.package,
        practitionerId: service.user_id,
        consultationId: service.consultationId
      });
    },
    getServices(packageId) {
      return this.services.filter(s => s.package.id === packageId)
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatTime(time) {
      return time.substr(0, time.lastIndexOf(":"));
    },
    formatServiceTitle(serviceTitle) {
      let ret = serviceTitle.substr(0, 27)
      if (serviceTitle.length > 27) {
        ret = ret + '...';
      }
      return ret;
    }
  },
  components: {
    Busy
  }
};
</script>

<style>
div.packageTile {
  border-right: 1px solid #eee;
  padding-right: 10px;
}
div.servicesTile {
  padding-left: 20px;
}
.card.serviceCard .card-body {
  padding: 0;
  height: 130px;
  overflow: hidden;
}
h6.serviceDuration {
  font-size: 80%;
}
h5.serviceTitle {
  margin-top: 15px;
  font-size: 90%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.unbookedPackages .image img {
  max-height: 100%;
  min-height: 120px;
}
.unbookedPackages .image {
  position: relative;
  height: 120px;
  min-height: 120px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.unbookedPackages {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
}
</style>
