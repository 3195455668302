<template>
  <div>
    <div class="row text-center my-5">
      <div class="col">
        <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>